import React from 'react'
import { report404Error } from 'src/utils/frontendLogger'
import Header from '@/src/components/HeaderV2'

export default function FourOhFour() {
  React.useEffect(() => {
    report404Error()
  }, [])
  return (
    <div>
      <Header isWhiteBg isFixed />
      <div
        style={{
          display: 'flex',
          height: '100vh',
          width: '100vw',
          alignItems: 'flex-end',
          overflowX: 'hidden',
          backgroundColor: '#E5E5E5'
        }}
      >
        <img src={'/imgs/404Image.png'} style={{ height: '80%' }} />
      </div>
    </div>
  )
}
